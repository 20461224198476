var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comparisons-list"},[_c('f-loading',{attrs:{"shown":_vm.loading}}),_c('div',{staticClass:"mb-3"},[_c('f-pagination',{staticClass:"mb-3",model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}}),_c('f-search-field',{model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('div',{staticClass:"comparisons-list__table scroll-wrapper"},[_c('f-table',{attrs:{"fields":_vm.tableFields,"items":_vm.comparisons,"hover":"","selectable":"","select-mode":"single"},on:{"row-clicked":_vm.onSelect},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.localOffset + index + 1)+" ")]}},{key:"cell(size)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("size")(item.size))+" ")]}},{key:"cell(createdDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(+item.createdDate))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"app-tooltip__action-icon mr-3 table__icon",on:{"click":function($event){$event.stopPropagation();return _vm.deleteComparison(item.id)}}},[_c('f-icon',{attrs:{"name":"trash"}}),_c('span',{staticClass:"app-tooltip__tooltip"},[_vm._v("Delete")])],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }