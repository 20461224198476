import axios from '@/services/axios-instance';
import { API_URL } from '@/services/api-url/api-url.service';
import request from '@/request';
export default class BackupsComparisonApi {
    static async create(body) {
        await axios.post(`${API_URL}/comparison-backup-to-backup`, body);
    }
    static find(params) {
        return request
            .get(`/comparison-backup-to-backup`, {
            params,
        })
            .then(({ data }) => data);
    }
    static remove(id) {
        return request.delete(`/comparison-backup-to-backup/${id}`);
    }
    static createProject() {
        return request.post(`/comparison-backup-to-backup/create-project`).then(({ data }) => data);
    }
    static fetchObjects(id, params) {
        return request
            .get(`/comparison-backup-to-backup/${id}/objects`, {
            params,
        })
            .then(({ data }) => data);
    }
    static fetchDescribe(body) {
        return request.post(`/comparison-backup-to-backup/describe-objects`, body).then(({ data }) => data);
    }
    static fetchRecords(id, params) {
        return request
            .get(`/comparison-backup-to-backup/${id}/records`, {
            params,
        })
            .then(({ data }) => data);
    }
    static async merge(id, body) {
        await request.put(`/comparison-backup-to-backup/${id}/merge`, body);
    }
    static async restore(body) {
        await request.post(`/comparison-backup-to-backup/restore`, body);
    }
}
