import { __decorate } from "tslib";
import { Vue, Component, Inject } from 'vue-property-decorator';
import JobsList from '@/components/shared/JobsList.vue';
import ComparisonsList from '@/components/backups-comparison/ComparisonsList.vue';
import BackupsComparisonApi from '@/services/api/backups-comparison.api';
let BackupsComparisonIndexPage = class BackupsComparisonIndexPage extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
    }
    async showLogs(query) {
        await this.$router.push({
            name: 'backups-comparison.logs',
            query,
        });
    }
    createComparison() {
        this.$router.push({
            name: 'backups-comparison.new',
        });
    }
    async gotoComparison(comparison) {
        try {
            if (comparison.status === 'In Progress') {
                this.$toast.error("You cannot view comparison that in status 'In Progress'");
                return;
            }
            this.loading = true;
            const { projectName } = await BackupsComparisonApi.createProject();
            await this.$router.push({
                name: 'backups-comparison.manage',
                params: {
                    comparisonId: comparison.id.toString(),
                },
                query: {
                    projectName,
                },
            });
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Inject()
], BackupsComparisonIndexPage.prototype, "organizationId", void 0);
BackupsComparisonIndexPage = __decorate([
    Component({
        components: {
            JobsList,
            ComparisonsList,
        },
    })
], BackupsComparisonIndexPage);
export default BackupsComparisonIndexPage;
