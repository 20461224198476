import { __decorate } from "tslib";
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import BackupsComparisonApi from '@/services/api/backups-comparison.api';
let ComparisonsList = class ComparisonsList extends Vue {
    constructor() {
        super(...arguments);
        this.TABLE_FIELD_MAP = {
            id: { label: 'Id' },
            name: { label: 'Name', thStyle: { width: '150px' } },
            description: { label: 'Description' },
            createdDate: { label: 'Created Date', thStyle: { width: '200px' } },
            size: { label: 'Size', thStyle: { width: '100px' } },
            status: { label: 'Status', thStyle: { width: '165px' } },
            actions: { label: '', thStyle: { width: '70px' } },
        };
        this.loading = false;
        this.searchText = '';
        this.pagination = {
            page: 1,
            total: 0,
            limit: 25,
        };
        this.comparisons = [];
    }
    get localOffset() {
        return (this.pagination.page - 1) * this.pagination.limit;
    }
    get tableFields() {
        const fields = [{ key: 'index', label: '#', thStyle: { width: '6%' } }];
        fields.push(...this.shownColumns.map(columnName => {
            return {
                key: columnName,
                ...this.TABLE_FIELD_MAP[columnName],
            };
        }));
        return fields;
    }
    onSelect(row) {
        return row;
    }
    mounted() {
        this.fetchComparisons();
    }
    async deleteComparison(id) {
        try {
            this.loading = true;
            await BackupsComparisonApi.remove(id);
            this.$toast.success('Comparison successfully deleted');
            await this.fetchComparisons();
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
    onPaginationChanged() {
        this.fetchComparisons();
    }
    async fetchComparisons() {
        try {
            this.loading = true;
            const { total, comparisons } = await BackupsComparisonApi.find({
                search: this.searchText,
                page: this.pagination.page,
                limit: this.pagination.limit,
                organizationId: this.organizationId,
            });
            this.comparisons = comparisons;
            this.pagination.total = total;
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Prop({
        default: [],
    })
], ComparisonsList.prototype, "shownColumns", void 0);
__decorate([
    Prop()
], ComparisonsList.prototype, "organizationId", void 0);
__decorate([
    Emit()
], ComparisonsList.prototype, "onSelect", null);
__decorate([
    Watch('pagination.page', { deep: true }),
    Watch('searchText')
], ComparisonsList.prototype, "onPaginationChanged", null);
ComparisonsList = __decorate([
    Component({})
], ComparisonsList);
export default ComparisonsList;
